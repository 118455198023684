import React from "react"
import cn from "classnames"
import { AriaButton } from "~components/Button"
import WarningIcon from "~images/inline/circle-warning.svg"

const DidomiOverlay = ({
  className,
  error,
  consent,
  title,
  buttonLabel,
  onClick,
  ...props
}) => {
  if (consent !== undefined && error) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center bg-gray-extralight px-6 py-12 text-center">
        <WarningIcon
          className="mb-3"
          style={{ width: "64px", height: "64px" }}
        />
        <span className="text-red">{error}</span>
      </div>
    )
  }

  return (
    <div
      className={cn(
        "flex h-full w-full flex-col items-center justify-center bg-gray-light px-6 py-12 text-white",
        className
      )}
      {...props}
    >
      {(consent === false || consent === undefined) && (
        <>
          <span className="mb-3 text-center">{title}</span>
          <AriaButton
            className="bg-greenAllow cursor-pointer rounded px-6 py-4"
            onPress={onClick}
          >
            {buttonLabel ? buttonLabel : "Autoriser"}
          </AriaButton>
        </>
      )}
    </div>
  )
}

export default DidomiOverlay
