import React from "react"
import AppThemeProvider from "~theme"
import { OverlayProvider } from "@react-aria/overlays"
import { SSRProvider } from "@react-aria/ssr"
import { DidomiProvider } from "~components/Didomi/DidomiContext"
import { DidomiBadge } from "~components/Didomi"

const LayoutWrapper = ({ children, ...props }) => {
  return (
    <AppThemeProvider {...props}>
      <SSRProvider>
        <DidomiProvider>
          <OverlayProvider>{children}</OverlayProvider>
          <DidomiBadge />
        </DidomiProvider>
      </SSRProvider>
    </AppThemeProvider>
  )
}

export default LayoutWrapper
