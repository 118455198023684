import React, { useRef } from "react"
import { useButton } from "@react-aria/button"

const AriaButton = ({ children, className, ...props }) => {
  const ref = useRef()
  const { buttonProps } = useButton(props, ref)

  return (
    <button {...buttonProps} className={className} ref={ref}>
      {children}
    </button>
  )
}

export default AriaButton
