import React from "react"
import styled from "styled-components"
import cn from "classnames"
import { AriaButton } from "~components/Button"
import { useDidomi } from "./DidomiContext"

const Badge = styled(AriaButton)`
  display: flex;
  body.didomi-popup-open & {
    display: none;
  }
`

const DidomiBadge = ({ className, ...props }) => {
  const { status } = useDidomi()

  if (!status) {
    return null
  }

  return (
    <Badge
      className={cn(
        "fixed bottom-3 left-3 z-[2010] min-h-[40px] min-w-[40px] items-center justify-center rounded-full bg-blue p-2 text-sm text-white shadow-[0_2px_20px_0_rgba(0,0,0,0.3)] transition-colors duration-200 ease-in-out md:py-2 md:pl-2 md:pr-3",
        className
      )}
      onPress={() => {
        window.Didomi.preferences.show()
      }}
      aria-label="Gestion des cookies"
      {...props}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        stroke="#fff"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
        <path d="M22 4 12 14.01l-3-3" />
      </svg>
      <span className="display-none ml-2 md:block">Gestion des cookies</span>
    </Badge>
  )
}

export default DidomiBadge
