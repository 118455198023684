// Define the variables used by tailwind and ThemeProvider

module.exports = {
  screens: {
    sm: "640px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
    "2xl": "1536px",
  },
  colors: {
    transparent: "transparent",
    blue: "#004489",
    vermilion: "#F54000",
    orange: "#D14816",
    "gray-dark": "#313436",
    gray: "#5F646A",
    "gray-light": "#848C96",
    "gray-extralight": "#F7F7F7",
    white: "#FFFFFF",
    black: "#000000",
    greenAllow: "#1B870B",
    red: "#aa0000",
  },
}
